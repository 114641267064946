<template>
  <v-sheet class="wsRoundedHalf pa-6 mt-6">
    Order : {{ orderId}}
  </v-sheet>
</template>

<script>
export default {
  name: "activeOrders",
  props : {
    orderId : {
      type : String,
      default : ''
    }
  }
}
</script>

<style scoped>

</style>